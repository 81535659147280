import {partyApi, usersApi, logout} from '../common'
import {actions} from './reducer'
import {diskStore} from '../DiskStore'
import {User} from './AppState'
import {store} from '../store'
import {WIDGET_ID} from '../manifest'
import {SearchParameters} from '@bakkt/api'
import PermissionManager from './PermissionManager'
import {Application as BakktComponentApplication} from '@bakkt/components'
class Application {
	respenseReceived() {
		const dispatch = store.dispatch
		dispatch(actions.responseReceived())
	}
	requestSent() {
		const dispatch = store.dispatch
		dispatch(actions.requestSent())
	}
	async fetchUserInfo() {
		const info = await partyApi.getUserSessionInfo()
		const roles = [...info.data.roles]
		const email = info.data.email
		const permissions = [...info.data.permissions]

		return {
			email,
			roles,
			permissions,
		} as User
	}
	async fetchAccountActivityReasonMapping() {
		const info = await partyApi.getAccountActivityReasonMapping()
		return info.data?.payload
	}

	async getZendeskUserList() {
		const dispatch = store.dispatch
		const request: SearchParameters = {pageNumber: 0, pageSize: 999}
		const response = await usersApi.getPortalUsers(request)
		const {content} = response.data.payload || {content: []}
		const users = content.filter(user => user.zendeskUserRef)
		dispatch(actions.zendeskUserLoad(users))
	}

	async loadOnStartup() {
		this.cleanupLastLogin()
		const dispatch = store.dispatch
		const currentUser = await this.fetchUserInfo()
		PermissionManager.setCurrentUser(currentUser)
		// handle no portal permissions case
		if (!PermissionManager.isAnyCSRAdminPermission()) {
			logout()
		} else {
			const accountActivityReasonMapping = await this.fetchAccountActivityReasonMapping()
			dispatch(actions.setReasonCodeMappings(accountActivityReasonMapping))
			dispatch(actions.staticDataLoad(currentUser))
			const widgets = await diskStore.getAll('Widget')

			dispatch(actions.loadWidgetsForUser(JSON.parse(localStorage.getItem('last_app_config'))))
			setInterval(() => {
				this.updateSetting()
			}, 30000)
		}
	}

	updateSetting() {
		const settings = JSON.stringify(store.getState().App.workspace)
		localStorage.setItem('last_app_config', settings)
	}
	cleanupLastLogin() {
		localStorage.removeItem('dashboard-blacklist-bank-view.tfilters')
		localStorage.removeItem('dashboard-blacklist-customer-view.tfilters')
	}

	openTab(name: string, typeId: WIDGET_ID, options?: any, id?: string, newTab?: boolean) {
		const dispatch = store.dispatch
		dispatch(actions.addWidget({title: name, type: typeId, id, options, newTab, isClosable: true}))
	}

	activateTab(id: string) {
		const dispatch = store.dispatch
		dispatch(actions.activateTab(id))
	}

	setTimeZone(timezone: string) {
		BakktComponentApplication.timezone = timezone
	}

	getTimeZone() {
		return BakktComponentApplication.timezone
	}
}

export default new Application()
