import React from 'react'
import {HBox, Icons, Menu, MenuItem} from '@bakkt/components'
import application from '../../app/Application'
import {WIDGET_ID} from '../../manifest'

interface IProps {}

interface IState {
	anchorEl: Element | null
	clientY: number
	clientX: number
}

export default class SettingsView extends React.Component<IProps, IState> {
	constructor(props: any) {
		super(props)
		this.state = {
			anchorEl: null,
			clientY: 0,
			clientX: 0,
		}
	}

	handleClick = (event: any) => {
		this.setState({anchorEl: event.currentTarget, clientX: event.clientX, clientY: event.clientY})
	}

	handleClose = () => {
		this.setState({anchorEl: null, clientX: 0, clientY: 0})
	}

	showUsersTab = () => {
		this.handleClose()
		application.openTab(
			`User Management`,
			WIDGET_ID.USERS,
			{
				id: WIDGET_ID.USERS,
				viewInTab: true,
			},
			WIDGET_ID.USERS,
		)
	}

	showRoleManagementTab = () => {
		this.handleClose()
		application.openTab(
			`Role Management`,
			WIDGET_ID.ROLE_MANAGEMENT,
			{
				id: WIDGET_ID.ROLE_MANAGEMENT,
				viewInTab: true,
			},
			WIDGET_ID.ROLE_MANAGEMENT,
		)
	}

	render() {
		const {anchorEl, clientY, clientX} = this.state
		return (
			<>
				<span
					style={{fontSize: 16, cursor: 'pointer', fontFamily: 'BrownPro', marginRight: 35}}
					onClick={e => this.handleClick(e)}
				>
					<HBox>
						<span>Settings</span> <Icons.ChevronDownSmll />
					</HBox>
				</span>
				<Menu
					anchorPosition={{top: clientY + 15, left: clientX - 60}}
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
				>
					<MenuItem onClick={this.showUsersTab}>User Management</MenuItem>
					<MenuItem onClick={this.showRoleManagementTab}>Role Management</MenuItem>
				</Menu>
			</>
		)
	}
}
