import {IndexDB} from '@bakkt/components'

export const diskStore = new IndexDB('Cache')

diskStore.initialize([
	{
		id: 'Alert',
		keyPath: 'id',
	},
	{
		id: 'Widget',
		keyPath: 'id',
	},
])
