import * as api from '@bakkt/api'
// Use openapi axios instance
import application from '../app/Application'
import axios from 'axios'
import Alert from './components/Alert'
const SERVER_URL = window['SERVER_URL'] == '{{SERVER_URL}}' ? process.env.REACT_APP_SERVER_URL : window['SERVER_URL']
export const redirectToLogin = () => {
	axios.get(`${SERVER_URL}/web/login-redirect?client=admin`).then(response => {
		if (response.headers['content-type'] === 'text/html;charset=UTF-8')
			window.location = response.request.responseURL
	})
}

const globalErrorHandler = (error: any) => {
	application.respenseReceived()
	if (error.response && error.response.status === 401) {
		redirectToLogin()
	}
	return Promise.reject({...error})
}

export const logout = () => {
	axios.post(`${SERVER_URL}/web/logout`).then(response => {
		window.location.reload()
	})
}

/*
 * Takes an array of fusionAuthRefs to destroy the sessions for on a permissions change
 */
export const destroyUserSessions = async userRefArray => {
	return axios.post(`${SERVER_URL}/web/destroy-sessions`, {
		fusionAuthRefs: userRefArray,
	})
}

axios.interceptors.request.use(req => {
	application.requestSent()
	return req
})

const globalSuccessHandler = (response: any) => {
	application.respenseReceived()

	return response
}
const baseUrl = SERVER_URL

axios.interceptors.response.use(globalSuccessHandler, globalErrorHandler)
// send session cookie
axios.defaults.withCredentials = true

const configuration: api.Configuration = {
	baseOptions: {},
	isJsonMime: mime => true,
}

export const importAllowList = (file: File) => {
	const formData = new FormData()
	formData.append('file', file)
	axios
		.post(`${baseUrl}/aof/allow-list/import`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.catch(reason => {
			console.log(reason)
			Alert.error('Something went wrong while uploading Allow List CSV File.')
		})
}

export const allowListApi = api.AllowListManagementApiFactory(configuration, baseUrl)

export const accountActivityApi = api.AccountActivityControllerApiFactory(configuration, baseUrl)

export const disputeApi = api.BusinessPortalDisputeControllerApiFactory(configuration, baseUrl)

export const ticketApi = api.BusinessPortalTicketControllerApiFactory(configuration, baseUrl)

export const usersApi = api.UserRoleManagementControllerApiFactory(configuration, baseUrl)

export const transactionApi = api.TransactionControllerApiFactory(configuration, baseUrl)

export const partyApi = api.BusinessPortalPartyControllerApiFactory(configuration, baseUrl)

export const incentiveApi = api.BusinessPortalIncentiveControllerApiFactory(configuration, baseUrl)

export const activityAuditApi = api.ActivityAuditControllerApiFactory(configuration, baseUrl)

//export const partyControllerApi = api.PartyApiFactory(configuration, baseUrl)

export const ofacApi = api.OfficeOfForeignAssetsControlOFACApiFactory(configuration, baseUrl)

export const giftCardApi = api.BusinessPortalGiftCardControllerApiFactory(configuration, baseUrl)

export const reportApi = api.BusinessPortalReportControllerApiFactory(configuration, baseUrl)

export const cardApi = api.BusinessPortalCardControllerApiFactory(configuration, baseUrl)

export const achApi = api.BusinessPortalAchControllerApiFactory(configuration, baseUrl)

export const blackListedApi = api.BusinessPortalBankAccountControllerApiFactory(configuration, baseUrl)

export const loyaltyApi = api.BusinessPortalLoyaltyControllerApiFactory(configuration, baseUrl)

export const riskApi = api.BusinessPortalRiskControllerApiFactory(configuration, baseUrl)
