import {lazy, Suspense} from 'react'
import Header from './app/components/Header'
import './App.css'
import Loading from './app/components/Loading'
import {useSelector} from 'react-redux'
import {selectCurrentUser} from './app/reducer'
import {VBox} from '@bakkt/components'
const Workspace = lazy(() => import('./app/components/Workspace'))
function App() {
	const currentUser = useSelector(selectCurrentUser)
	if (!currentUser) {
		return <Loading />
	}
	return (
		<>
			<VBox className='App' style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 15}}>
				<Header></Header>
				<Suspense fallback={<Loading />}>
					<Workspace />
				</Suspense>
			</VBox>
			<div id='popupArea' />
			<div id='popupMenu' />
		</>
	)
}

export default App
