import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AlertState, Alert} from './AlertState'
import {diskStore} from '../DiskStore'
import {RootState} from '../store'
const type = 'Alert'

const init: AlertState = {
	alerts: [],
	isTrayOpen: false,
}
export const alertSlice = createSlice({
	name: 'counter',
	initialState: init,
	reducers: {
		addAlerts: (state: AlertState, action: PayloadAction<Alert[]>) => {
			diskStore.putBatch(type, action.payload)
			state.alerts = [...state.alerts, ...action.payload]
		},
		dismissAlerts: (state: AlertState, action: PayloadAction<Alert[]>) => {
			diskStore.removeBatch(
				type,
				action.payload.map(a => a.id),
			)
			state.alerts = state.alerts.filter(a => action.payload.find(b => a.id === b.id) === null)
		},
		dismissAll: state => {
			state.alerts = []
		},
		recoverAlerts: (state: AlertState, action: PayloadAction<Alert[]>) => {
			state.alerts = [...state.alerts, ...action.payload]
		},
	},
})

export const selectAlerts = (state: RootState) => state.Alerts.alerts
export const {addAlerts, dismissAlerts, dismissAll, recoverAlerts} = alertSlice.actions

export default alertSlice.reducer
