import React from 'react'
import {logout} from '../../common/api'
import {Icons, HBox} from '@bakkt/components'
import SettingsView from './Settings'
import PermissionManager, {PortalPermission} from '../PermissionManager'

export default () => {
	return (
		<div style={{marginLeft: 100, marginRight: 100, marginTop: 25, marginBottom: 25}}>
			<HBox style={{alignItems: 'center'}}>
				<span style={{fontSize: 20, fontFamily: 'BrownPro'}}>The Tango Portal</span>
				<div style={{display: 'flex', flexGrow: 1}}></div>
				<Icons.BakktLogo />
				<div style={{display: 'flex', flexGrow: 1}}></div>
				{PermissionManager.userAllowed([PortalPermission.USERS_VIEW]) && <SettingsView />}
				<span style={{fontSize: 16, cursor: 'pointer', fontFamily: 'BrownPro', height: 24}} onClick={logout}>
					Sign Out
				</span>
			</HBox>
		</div>
	)
}
